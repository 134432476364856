declare const require: any;

export const environment = {
    production: true,
    apiV3Url: "https://api-pp.margaret.app",
    advertisersApiUrl: "https://api-annonceurs-pp.margaret.app",
    defaultRadius: 10000,
    googleMapsApiKey: "AIzaSyCvggQ2UGkRUP56wAqijAJTuOK0uUgm9u8",
    appleClientID: "app.margaret.margaret-pp",
    appleRedirectURI: "https://margaret-pp.margaret.app/login",
    shareRootUrl: "https://margaret-pp.margaret.app",
    shareImageUrl: "https://margaret-pp.margaret.app/assets/icon/margaret.png",
    facebookAppId: "356656386476819",
    twitterSite: "",
    desktopVersion: require("../../web-ssr/package.json").version,
};
